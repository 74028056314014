<template>
  <div class="flex gap-8 min-h-screen p-16">
    <img src="@/assets/images/logo.jpg" alt="WIDM Logo" class="w-32 h-32" />

    <div class="flex-1 relative z-10">
      <div
        v-if="state == 'start'"
        class="flex justify-center items-center h-full"
      >
        <div class="flex flex-col gap-2">
          <label for="name" class="text-lg text-white">Naam</label>
          <div class="flex rounded overflow-hidden">
            <input
              type="text"
              v-model="name"
              placeholder="Vul hier je naam in..."
              id="name"
              class="flex-1 p-4 w-64"
              autocomplete="off"
            />
            <button
              @click="submitName"
              @mousedown="playClick"
              class="bg-gradient-to-b from-mol to-mol-dark hover:from-mol-light hover:to-mol text-white material-icons w-14 h-14"
            >
              arrow_forward
            </button>
          </div>
        </div>
      </div>

      <div v-if="state == 'question'" class="flex flex-col items-stretch">
        <header
          class="flex items-center h-32 gap-8 text-white text-3xl tracking-wider"
        >
          <div class="w-8">{{ currentQuestion.question }}</div>
          {{ currentQuestion.text }}
        </header>

        <main class="relative z-10 grid grid-cols-3 gap-8">
          <div
            v-for="answer in currentQuestion.answers"
            :key="answer.id"
            class="flex gap-8 items-center"
          >
            <button
              @click="submitAnswer(answer.id)"
              @mousedown="playClick"
              class=" hover:bg-mol-light text-white material-icons w-8 h-8 rounded bg-gradient-to-b from-mol to-mol-dark hover:from-mol-light hover:to-mol"
            ></button>
            <span class="text-xl text-white tracking-widest">
              {{ answer.text }}
            </span>
          </div>
        </main>
      </div>

      <div
        v-if="state == 'loading'"
        class="flex justify-center items-center h-full"
      >
        <span class="text-white text-lg tracking-widest">
          <!-- Loading... -->
        </span>
      </div>

      <div
        v-if="state == 'done'"
        class="flex justify-center items-center h-full"
      >
        <span class="text-white text-lg tracking-widest">
          Bedankt voor het invullen van de test
        </span>
      </div>
    </div>

    <!-- SPACER -->
    <div
      class="relative z-10 w-32 h-32 flex flex-col justify-center items-center gap-2"
      @click="toggleMusic"
    >
      <img src="@/assets/images/scout-logo.svg" class="w-12 h-12 text-white" />
      <div class="text-white text-center tracking-widest">
        JOTA 2021
      </div>
    </div>

    <!-- Background image -->
    <img
      src="@/assets/images/logo.jpg"
      class="fixed right-0 bottom-0 h-full opacity-25 w-auto filter blur-sm z-0"
    />
  </div>
</template>

<script>
import axios from "axios";

import { useSound } from "@vueuse/sound";
import clickAudio from "./assets/audio/klik.wav";
import testMusic from "./assets/audio/test.mp3";

export default {
  name: "App",
  data: () => ({
    state: "start",
    currentQuestion: null,
    name: "",
    music: false
  }),
  setup() {
    const { play: playClick } = useSound(clickAudio);
    const { play: playMusic, stop: stopMusic } = useSound(testMusic, {
      volume: 0.5,
      onend: () => {
        playMusic();
      }
    });

    return {
      playClick,
      playMusic,
      stopMusic
    };
  },
  methods: {
    toggleMusic() {
      if (this.music) {
        this.stopMusic();
      } else {
        this.playMusic();
      }
      this.music = !this.music;
    },
    submitName() {
      this.state = "loading";
      axios.get("http://widm-api.bramdevriez.nl").then(response => {
        this.state = "question";
        this.currentQuestion = response.data;
      });
    },
    submitAnswer(answerId) {
      this.state = "loading";

      const data = new FormData();

      data.append("name", this.name);
      data.append("questionId", this.currentQuestion.id);
      data.append("answerId", answerId);

      axios
        .post("http://widm-api.bramdevriez.nl/submit-answer.php", data)
        .then(response => {
          this.currentQuestion = response.data;

          if (response.data == null) {
            const that = this;

            this.name = null;
            this.state = "done";

            setTimeout(function() {
              that.state = "start";
            }, 3000);
          } else {
            this.state = "question";
          }
        });
    }
  }
};
</script>
